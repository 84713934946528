
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';


const formatData = (data, key) => {
    const formatted = {
        labels: data.map(d => (new Date(d.date)).toDateString()),
        datasets: [
            {
                label: key,
                data: data.map(d => d[key]),
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            }
        ]
    }
    console.log('formatted', key, formatted);
    return formatted;
}

const InsightChart = (props) => {

    const { data, insight } = props;

    const options = { };

    return (
        <>
        {
            data && data.length > 0 &&
            <Line data={formatData(data, insight)} options={options}/>
        }
        </>
    )

}

export default InsightChart;
