import {getLocation} from '../endpoints/googlebusinessprofile.locations'
import {useQuery, } from '@tanstack/react-query'
import {Link, useParams, useLocation} from 'react-router-dom'

const GoogleBusinessProfileLocation = () => {

    const {locationId} = useParams()
    const account = useLocation()
    const {accessToken} = account.state
    const {data} = useQuery({queryKey: ['googleBusinessLocation', locationId, accessToken], queryFn: getLocation})
    let location
    let street
    let address

    if(data) {
        location = data
        street = location.storefrontAddress.addressLines.join(' ')
        address = `${street} ${location.storefrontAddress.locality}, ${location.storefrontAddress.administrativeArea} ${location.storefrontAddress.postalCode}`
    }

    return(
        <>
         {data &&
            <div key={location.storeCode} className="location">
                <h1>{location.title}</h1>
                <div className="location__details">
                    <span className="address">{address}</span>
                    <span className="phone">{location.phoneNumbers.primaryPhone}</span>
                </div>
            </div>
         }
        </>
    )
}

export default GoogleBusinessProfileLocation