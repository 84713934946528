import axios from 'axios'

const getAllInsightsTest = async (req) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        }
    }
    let response = null;
    try {
        console.log('getallinsights');
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/insights`, config);
    } catch(err) {
        console.error(err);
    }

    return response.data
}

const getInsights = async (req) => {
    console.log(req);
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: req.queryKey && req.queryKey[1] ? { ...req.queryKey[1] } : {}
    }
    let response = null;
    try {
        console.log('getinsights');
        response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/insights/list`, config);
    } catch(err) {
        console.error(err);
    }

    return response.data
}

const getInsightsSummary = async (req) => {
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json"
        },
        params: req.queryKey && req.queryKey[1] ? { ...req.queryKey[1] } : {}
    }
    let response = null;
    try {
        console.log('getinsightssummary');
        response = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/insights/summary`, config);
    } catch(err) {
        console.error(err);
    }

    return response.data
}

export { getInsights, getAllInsightsTest, getInsightsSummary }