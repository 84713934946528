import {getAccounts} from '../endpoints/accounts'
import {useQuery, useMutation} from '@tanstack/react-query'

import { Card, Spinner } from '@nextui-org/react'
import { useEffect } from 'react';
import axios from 'axios';
import { getAllInsightsTest } from '../endpoints/insights';  

const getAllInsights = async () => {
    const accessToken = '';

    const config = {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    }
    try {
        console.log('getallinsights');
        await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/googlebusinessprofile/insights/sync`, config);
    } catch(err) {
        console.error(err);
    }
};

const Accounts = () => {

    const {data,isLoading} = useQuery({queryKey: ['accounts'], queryFn: getAccounts})

    const { data:insights, refetch } = useQuery({
        queryKey: ['insights'],
        queryFn: getAllInsights,
        enabled: false
    })
    useEffect(() => {
        console.log('Insights query triggered');
    }, [insights]);
    
    useEffect(() => {
        console.log('Refetching insights');
    }, [refetch]);

    let accountList = []
    if(data && !isLoading) {
        accountList = data?.data.map((account, idx) => {
            return (
                <Card key={`account-${idx}`} className="p-2">
                    <div key={account.googleId} className="google-account">
                        <span className="account__image">
                            {account?.image &&
                                <img src={account.image} alt={account.firstName} />
                            }
                        </span>
                        <div className="account__content">
                            <span className="account__name">
                                {account.firstName} {account.lastName}
                            </span>
                            <span className="account__email">
                                {account.email}
                            </span>
                        </div>
                    </div>
                </Card>
            )
        })
    }

    return(
        <>
        {/* <button onClick={() => {
            refetch();
        }}>Test Get insights</button> */}
        { data ? (<div className="flex flex-col gap-4 py-4">{accountList}</div>) : <div className="flex justify-center mx-auto"><Spinner/></div> }
        </>
    )
}

export default Accounts